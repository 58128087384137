<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100 md-xsmall-size-100">
      <md-card >
        <form novalidate @submit.prevent="validateUser">
          <md-card-header>
            <md-card-header-text>
              <div class="md-title" v-if="instance.id">Editando empleado</div>
              <div class="md-title" v-if="!instance.id">Crear empleado</div>
            </md-card-header-text>
            <md-menu md-size="big" md-direction="bottom-end">
              <md-button class="md-icon-button" @click="go_to(`/employes/`)">
                <md-icon>keyboard_backspace</md-icon>
              </md-button>
            </md-menu>
          </md-card-header>
          <md-card-content>
            <div class="md-layout md-gutter">
              <div class="md-layout-item">
                <md-field>
                  <label for="first_name">* Nombre</label>
                  <md-input name="first_name"
                  id="first_name"
                  autocomplete="given-name" v-model="instance.first_name" :disabled="sending" />
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100">
                <md-field >
                  <label for="name">* Apellido</label>
                  <md-input name="last_name"
                  id="last_name" v-model="instance.last_name" :disabled="sending" />
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100">
                <md-field>
                  <label for="email">* Correo eléctronico</label>
                  <md-input type="email"
                  id="email" name="email" autocomplete="email"
                  v-model="instance.email" :disabled="sending" />
                </md-field>
              </div>
            </div>
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <br>
                <md-switch v-model="instance.is_contador">
                  <span v-if="instance.is_contador">Es contador? SÍ</span>
                  <span v-if="!instance.is_contador">Es contador? NO</span>
                </md-switch>
              </div>
              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('prefijo_profesion')">
                  <label for="prefijo_profesion">Prefijo profesion (Lic. Ing.)</label>
                  <md-input type="text"
                  id="razonSocila" name="prefijo_profesion"
                  v-model="instance.prefijo_profesion"
                  :disabled="sending || !instance.is_contador" />
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('numero_registro_sat')">
                  <label for="numero_registro_sat">No. registro SAT</label>
                  <md-input type="text"
                  id="razonSocila" name="numero_registro_sat"
                  v-model="instance.numero_registro_sat"
                  :disabled="sending || !instance.is_contador" />
                </md-field>
              </div>
            </div>
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-field>
                  <label for="phone">Teléfono</label>
                  <md-input name="phone" id="phone" type="number"
                  v-model="instance.phone" :disabled="sending" />
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('nit')">
                  <label for="nit">NIT</label>
                  <md-input name="nit"
                  id="nit" v-model="instance.nit" type="text" :disabled="sending" />
                  <span class="md-error"
                  v-if="!$v.instance.nit.required">El NIT es requerido</span>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100" v-if="!instance.id">
                <md-field>
                  <label for="group">* Grupo</label>
                  <md-select v-model="instance.group">
                  <md-option value="">----</md-option>
                  <md-option :value="item.id"
                    v-for="(item, key) in groups_obj"
                    :key="key">{{item.name}}</md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100">
                <br>
                <label for="nit">Usuario: </label>

                  <span v-if="instance.user">{{instance.user_name}}</span>
                  <span v-else>-----</span>
              </div>
            </div>
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('address')">
                  <label for="address">Dirección</label>
                  <md-input type="text"
                  id="address" name="address" autocomplete="address"
                  v-model="instance.address" :disabled="sending" />
                  <span class="md-error"
                  v-if="!$v.instance.address.required">La dirección es requerida</span>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100">
                <md-field>
                  <label for="group">Tipo</label>
                  <md-select v-model="instance.user_type">
                    <md-option value="">----</md-option>
                    <md-option value="M">Administrador</md-option>
                    <md-option value="user">Usuario</md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
          </md-card-content>
          <md-progress-bar md-mode="indeterminate" v-if="sending" />
          <md-card-actions>
            <md-button
            type="submit" class="md-primary" :disabled="sending">Guardar cambios</md-button>
          </md-card-actions>
        </form>
      </md-card>
      <br>
      <br>
    </div>
    <md-snackbar
      :md-active.sync="userSaved">{{ lastUser }} ha sido actualizado!</md-snackbar>
  </div>
</template>
<script>
/* eslint-disable */
import { validationMixin } from 'vuelidate'
import {
  required,
  email,
} from 'vuelidate/lib/validators'

export default {
  name: 'Form_customer',
  created(){
    if  (this.$route.params.id) {
      this.instance.id = this.$route.params.id
      this.retrieveInvoice();
    }    
    if  (!this.$route.params.id) {      
      this.retrieveDataOffice();
    }
  },
  mixins: [validationMixin],
  data() {
    return {
      logo_url: '',
      base: process.env.VUE_APP_BASE_URL,
      instance: {
        first_name: null,
        last_name: null,
        email: '',
        address: 'CUIDAD',
        phone: null,
        nit: 'CF',
      },
      url: 'employes/',
      groups_obj: [],
      url_office: 'offices/',
      userSaved: false,
      sending: false,
      lastUser: null
    };
  },
  validations: {
    instance: {
      address: {
        required
      },
      nit: {
        required,
      },
    },
  },
  methods: {
    go_to(url) {
      this.$router.push(url);
    },
    save_storage(key, data) {
      localStorage.setItem([key], JSON.stringify(data));
    },
    set_logo(document_id) {
      let payload = {}
      payload.change_logo = 'True'
      payload.id = this.instance.id
      payload.url = document_id
      const self = this;
      this.retrieveRequest(this.url, payload).then((data) => {
        self.instance = data.data
        self.$swal.fire('Actualizado', 'Actualización exitosa.', 'success');
      }).catch((error) =>{
        this.handlerError(error)
      });
    },
    handlerError: (error) => {
      const result = error.response.data;
      const self = this;
      if (typeof (error.response.data) === 'object') {
        Object.entries(result).forEach(([key, value]) => { window.$swal.fire('Error!', `${key}: ${value}`, 'error'); });
        return false;
      }
      self.$swal.fire('Error!', result, 'error');
      return false;
    },
    list() {
      const payload = {};
      const self = this;
      this.sendRequest(payload, this.url).then((data) => {
        self.list_data = data.data;
      }).catch((error) => {
        console.log(error);
        self.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    getValidationClass (fieldName) {
      const field = this.$v.instance[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    clearForm () {
      this.$v.$reset()
    },
    validateUser () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.saveOrUpdate()
      }
    },
    saveOrUpdate () {
      if (!this.instance.first_name && !this.instance.last_name) {
        this.$swal.fire('Error!', 'Nombre y apellido son requeridos.', 'error')
        return false
      }
      const payload = this.instance
      const self = this
      this.sending = true
      if (this.instance.id) {
        this.putRequest(payload).then(function (data) {
          self.lastUser = `${self.instance.first_name} ${self.instance.last_name}`
          self.userSaved = true
          self.sending = false
          self.instance = data.data
          self.clearForm()
        }).catch(function (error) {
          self.sending = false
          if (typeof (error.response.data) === 'object') {
            const result = error.response.data
            Object.entries(result).forEach(
              ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
            )
            return false
          }
          self.$swal.fire('Error!', error.response.data, 'error')
        })
        return
      }
      payload.company = localStorage.getItem('office')
      if (!payload.company) {
        this.$swal.fire('Error!','La empresa es requerida' , 'error')
        return
      }
      if (this.$store.state.store_obj) {
        payload.store = this.$store.state.store_obj.id
      }
      if (!this.instance.first_name){
        this.instance.first_name = ''
      }
      this.postRequest(payload).then(function (data) {
        if (self.instance.first_name === ''){
          self.lastUser = `${self.instance.razon_social}`
        }
        if (self.instance.razon_social === null){
          self.lastUser = `${self.instance.first_name}`
        }
        self.userSaved = true
        self.sending = false
        self.clearForm()
        self.instance = data.data
      }).catch(function (error) {
        self.sending = false
        if (typeof (error.response.data) === 'object') {
          const result = error.response.data
          Object.entries(result).forEach(
            ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
          )
          return false
        }
        self.$swal.fire('Error!', error.response.data, 'error')
      })
    },
    async retrieveInvoice () {
      const self = this
      const payload = {
        id: this.instance.id,
        basic_serializer: 'True'
      }
      await this.retrieveRequest(this.url, payload).then(function (data) {
        self.instance = data.data
      }).catch(function (error) {
        self.handlerError(error)
      })
    },
    async retrieveDataOffice () {
      const self = this
      const payload = {
        id: localStorage.getItem('office'),
        basic_serializer: 'True'
      }
      await this.retrieveRequest(this.url_office, payload).then(function (data) {
        self.groups_obj = data.data.groups_obj
      }).catch(function (error) {
        self.handlerError(error)
      })
    },
    async sendRequest(payload, url) {
      const result = await this.$http.get(url,{params:  payload});
      return result;
    },
    async postRequest (payload) {
      const result = await this.$http.post(this.url, payload)
      return result
    },
    async putRequest (payload) {
      const result = await this.$http.put(`${this.url}${payload.id}/`, payload)
      return result
    },
    async retrieveRequest (url, _payload) {
      const result = await this.$http.get(url + _payload.id + '/', { params: _payload })
      return result
    },
  },
  filters: {
    status_to_text(value){
      if (value) return 'Sí';
      return 'No';
    }
  },
  computed: {
    settings_obj() {
      return this.$store.state.settings_obj;
    },
  },
};

</script>
<style lang="css" scoped>
 .success {
    font-weight: 500;
    background-color: #4aad09;
  }
  .warning {
    font-weight: 500;
    background-color: yellow;
  }
  .danger {
    font-weight: 500;
    background-color: red;
  }
  table {
    font-size: 2em;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 1000px #424242 inset;
  }
</style>
